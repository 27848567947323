var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('UserAddEdit',{attrs:{"es-id":_vm.ae.esId,"stepper-mode":_vm.ae.stepperMode,"stepper-step":_vm.ae.stepperStep,"user-id":_vm.ae.userId,"user-data":_vm.ae.userData}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing px-4"},[_c('title-plus',{staticClass:"mb-10",attrs:{"title":_vm.$t('components.operatorUsersManagement.headline')},on:{"plus":_vm.onOpenES}}),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.operatorUsersManagement.summary.totalOperators'),"value":_vm.indexMetaData.summary.total,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.operatorUsersManagement.summary.ongoingTasks'),"value":_vm.indexMetaData.summary.total_ongoing_task,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.operatorUsersManagement.summary.completedTasks'),"value":_vm.indexMetaData.summary.total_completed_task,"variant":"gray"}})],1)],1),[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"qso":_vm.qso,"endpoint":_vm.indexDataEndpoint,"headers":_vm.getTableHeaders},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewOrganizationUserProfile',
                      params: { id: item.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.full_name)+" ")])],1),_c('FSTableRowItem',{attrs:{"text":item.phone_number}}),_c('FSTableRowItem',{attrs:{"text":item.total_points}}),_c('FSTableRowItem',[(item.last_login)?_c('div',[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.last_login, 'D MMM YYYY, h:mm:ss a' ))+" ")]):_c('div',[_vm._v("--")])]),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":_vm.getUserStatusVariant(item.user_status),"text":_vm.getUserStatus(item.user_status),"profile":"user"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center gap-3"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}),_c('UserIndexActions',{attrs:{"primary-key":itemIndex,"data":item},on:{"accountStatusUpdated":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewOrganizationUserProfile',
                      params: { id: item.id },
                    },"target":"_blank"}},[_vm._v(" "+_vm._s(item.full_name)+" ")])],1),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":_vm.getUserStatusVariant(item.user_status),"text":_vm.getUserStatus(item.user_status),"profile":"user"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center gap-3"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.onOpenES(item.id)}}}),_c('UserIndexActions',{attrs:{"primary-key":itemIndex,"data":item},on:{"accountStatusUpdated":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.operatorUsersManagement.table.columns.phoneNumber' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.phone_number || '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.operatorUsersManagement.table.columns.totalPoints' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.total_points || '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.operatorUsersManagement.table.columns.lastLogin' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.last_login)?_c('div',[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.last_login, 'D MMM YYYY, h:mm:ss a' ))+" ")]):_c('div',[_vm._v("--")])])])])]):_vm._e()]:_vm._e()]})]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }